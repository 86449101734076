/**
 *
 * Helpers
 *
 */


.spacer {
	height: 2rem;
}

.spacer-lg {
	height: 4rem;
}

.spacer-xl {
	height: 6rem;
}

.spacer-xxl {
	height: 8rem;
}

.col--no-padding {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
