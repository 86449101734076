/*--------------------------------------------------------------
Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers */
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.screen-reader-text:hover,
.screen-reader-text:active,
.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000; /* Above WP toolbar */
}

/*--------------------------------------------------------------
Captions
--------------------------------------------------------------*/
.wp-caption {
	margin: 0 0 2rem 0;
	padding-top: 1rem;
	max-width: 100%;

	.wp-caption-text {
		margin: 0;
		padding: 1rem 0 0 0;
		text-align: center;
		font-size: .8125rem;
	}

	img[class*="wp-image-"] {
		display: block;
		margin: 0 auto;
	}
}


/*--------------------------------------------------------------
Comments
--------------------------------------------------------------*/
.comment-content a {
	word-wrap: break-word;
}


/*--------------------------------------------------------------
Images
--------------------------------------------------------------*/
.alignleft {
	float: left;
	margin: 0 30px 20px 0;
}
.alignright {
	float: right;
	margin: 0 0 20px 30px;
}
.aligncenter {
	display: block;
	margin: 0 auto 20px auto;
	clear: both;
}
.aligncenter.wp-caption {
	margin: 0 auto 2rem auto;
	max-width: 100%;
}
.alignnone {
	margin: 0 0 20px 0;
	clear: both;
}
