/**
 *
 * Certificates
 *
 */

.certificates {


}
	.certificate {
		text-align: center;
		padding-bottom: 1.5rem;
	}
