/**
 *
 * Google Map
 *
 */

.gm-map {
	display: block;
	width: 100%;
	height: 100%;
	min-height: 240px;

	@media (min-width: 992px) {
		min-height: 350px;
	}
}

// Sizes
.gm-map--lg {
	min-height: 300px;

	@media (min-width: 992px) {
		min-height: 450px;
	}
}

.gm-map--fixed {
	height: 300px;

	@media (min-width: 992px) {
		height: 450px;
	}
}
