/**
 *
 * Widget: Social Links
 *
 */

.widget-social {

	.widget__content {
		overflow: hidden;
	}
}
