/**
 *
 * Widget: Tag Cloud
 *
 */

.widget_tagcloud {

}
	.tagcloud {

		.btn {
			margin: 0 .5rem .75rem 0;
			text-transform: uppercase;
		}
	}
