/**
 *
 * Pricing Tables
 *
 */

// Wrapper
.pricing-table-wrapper {

	&.no-gutters {

		> .col,
		> [class*="col-"] {

			// removed border from all elements except the last one
			&:not(:last-child) {
				.box-outline {
					border-right: none;
				}
			}
		}
	}
}

// Pricing Intro
.pricing-table-intro {

	@include media-breakpoint-up(md) {
		padding-right: 50px;
	}
}

// Pricing Table
.pricing-table {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	text-align: center;
	height: 100%;

	@include media-breakpoint-down(sm) {
		margin-bottom: $spacer * 2;
	}

	@include media-breakpoint-down(md) {
		padding-left: $box-padding / 2.5 !important;
		padding-right: $box-padding / 2.5 !important;
		height: auto;
	}
}
.pricing-table--featured {
	position: relative;
	z-index: 2;
	background-color: lighten($box-bg, 3%);;

	@include media-breakpoint-up(md) {
		height: calc(100% + 40px);
		margin-top: -20px;
		padding-top: $box-padding + 20px;
		padding-bottom: $box-padding + 20px;
	}
}
	// Header
	.pricing-table__header {

	}
		// Title
		.pricing-table__title {

		}
		// Price
		.pricing-table__price {

			@include media-breakpoint-up(md) {
				font-size: $h1-font-size * 1.25;
				margin-bottom: .5em;
			}
		}
			.pricing-table__price-currency {
				font-size: $font-size-base;
				line-height: 1.2em;
				top: .5em;
				padding-right: .3em;
				vertical-align: top;
			}

	// Body
	.pricing-table__body {
		flex-grow: 1;
	}
		// Excerpt
		.pricing-table__excerpt {

		}
		// Features
		.pricing-table__list {
			border-top: 1px solid $secondary;

			&.list {
				text-align: left;
				margin-bottom: 0;
				margin-top: $box-padding / 1.5;
				padding-top: $box-padding / 1.5;
			}
		}

	// Footer
	.pricing-table__footer {
		padding-left: $box-padding / 2;
		padding-right: $box-padding / 2;
		padding-top: $box-padding;
	}
